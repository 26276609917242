import ScrollToTop from "../ScrollToTop";
import Order from "../components/Order/Order";
import Container from "../components/Container/Container";

const route = {
  path: "/order/:id",
  element: (
    <ScrollToTop>
        <Container><Order /></Container>
    </ScrollToTop>
  ),
};

export default route;
