import { createContext, useContext, useState } from "react";
import closeIcon from './modal-close.svg';
import './Modal.css';

export const ModalContext = createContext({});

function Modal({ body, title, narrow, sidebar }) {
  const { setModal } = useContext(ModalContext);

  const closeModal = (_) => {
    setModal(null);
  }

  return <div className={narrow ? 'modal-container is-narrow' : 'modal-container'}>
    <div className={sidebar ? 'modal has-sidebar' : 'modal'}>
      {sidebar && (
        <div className="modal-sidebar">
          {sidebar}
        </div>
      )}

      <div className="modal-contents">
        <div className="modal-heading">
          <h2>{title}</h2>
          <button type="button" onClick={closeModal}><img src={closeIcon} alt="Close modal" /></button>
        </div>

        <div className="modal-body">{body}</div>
      </div>
    </div>
  </div>
}

function Container({ children }) {
  const [modal, setModal] = useState(null);

  return <>
    <ModalContext.Provider value={{ setModal }}>
      {children}

      {modal && (
        <Modal {...modal} />
      )}
    </ModalContext.Provider>
  </>;
}

export default Container;
