import Header from "../Header/Header";
import {useState} from "react";

function Order() {
    const [house] = useState(null);

    return (
        <div className="house">
            <div className="container">
                <Header house={house}/>
                summary ...
            </div>
        </div>
    );
}

export default Order;
