import "./SectionModal.css";

function SectionModal({ products }) {
  return (
    <div className="section-modal">
      {/* {description && description.length ? <p className="section-modal-description">{description}</p> : null} */}

      <div className="section-modal-products">
        {products.map((product, index) => (
          <div key={index} className="section-modal-product">
            {product.thumbnail ? (
              <img src={product.thumbnail} alt={product.title} />
            ) : (
              <div className="section-modal-image-placeholder" />
            )}

            <h3>{product.title}</h3>

            {product?.show_info === true && (
              <div className="section-modal-product-body">
                {product.info &&
                  product.info.map((info, index) => (
                    <div key={index} className="section-modal-product-body-field">
                      {info.show_key ? (
                        <>
                          {info.key}: {info.value}
                        </>
                      ) : (
                        info.value
                      )}
                    </div>
                  ))}

                <div className="section-modal-product-body-field">Hind: {product.hind}&euro;</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SectionModal;
