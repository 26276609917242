import Header from "../Header/Header";
import "./House.css";
import { createContext, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../Home/Home";
import { useDebounce } from "../../hooks/useDebounce";
import Summary from "./Summary";

export const RoomContext = createContext({});
export const HouseContext = createContext({});
export const HomeContext = createContext({});
export const OrderContext = createContext({});

function House() {
	const [house, setHouse] = useState(null);
	const [home, setHome] = useState(null);
	const [room, setRoom] = useState(null);
	const [order, setOrder] = useState({});
	const orderDebounced = useDebounce(order, 350);
	const { houseId, homeId, orderId, summary } = useParams();
	const navigate = useNavigate();

	const getHouse = useCallback(() => {
		if (house !== null) {
			return;
		}

		axios
			.get(`${window.API_URL}/house/${houseId}`)
			.then(({ data }) => {
				const newHome = data.homes.find((x) => x.id === parseInt(homeId));
				setHouse(data);
				setHome(newHome);
				setRoom(newHome?.rooms[0]);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [houseId, homeId, house, setHouse, setHome]);

	const getOrder = (id) => {
		axios.get(`${window.API_URL}/order/${id}`).then(({ data }) => {
			setOrder(data.order);
		}).catch(error => {
			console.log(error);
		});
	}

	const composeDefaultOrderData = useCallback(() => {
		return {
			houseId,
			homeId,
			rooms: home?.rooms.map((room) => {
				return {
					name: room.room_name,
					sections: room.sections.length > 0 ? room.sections.filter(s => !s.optional).map((section) => {
						return {
							name: section.name,
							product: section.products[0]
						}
					}) : []
				}
			})
		};
	}, [home, houseId, homeId]);

	useEffect(() => {
		if (house !== null) {
			const newHome = house.homes.find((x) => x.id === parseInt(homeId));
			setHome(newHome);
			setRoom(newHome?.rooms[0]);

			if (orderId) {
				getOrder(orderId);
			} else {
				axios.post(`${window.API_URL}/order`, composeDefaultOrderData()).then(({ data }) => {
					setOrder(composeDefaultOrderData());
					navigate(`/house/${houseId}/${homeId}/${data.id}`);
				}).catch(error => {
					console.log(error);
				});
			}
		}
	}, [homeId, house, orderId, composeDefaultOrderData, houseId, navigate]);

	useEffect(() => {
		getHouse();
	}, [getHouse]);

	useEffect(() => {
		if (orderId && orderDebounced?.rooms && orderDebounced?.rooms.length) {
			axios.post(`${window.API_URL}/order/${orderId}`, orderDebounced).then(({ data }) => {
				//console.log('updated')
			}).catch(error => {
				console.log(error);
			});
		}
	}, [orderDebounced, orderId]);

	return (
		<RoomContext.Provider value={{ room, setRoom }}>
			<HouseContext.Provider value={{ house }}>
				<HomeContext.Provider value={{ home, setHome }}>
					<OrderContext.Provider value={{ order, setOrder }}>
						<div className="house">
							<div className="container">
								<Header house={house} home={home} />
								{summary ? (
									<Summary />
								) : (
									<Home {...home} />
								)}
							</div>
						</div>
					</OrderContext.Provider>
				</HomeContext.Provider>
			</HouseContext.Provider>
		</RoomContext.Provider>
	);
}

export default House;
