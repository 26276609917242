import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RoomContext } from "../House/House";
import "./Header.css";
import logo from "./logo.svg";

function Header({ house, home }) {
  const { room, setRoom } = useContext(RoomContext);
  const navigate = useNavigate();
  const { houseId, homeId, orderId, summary } = useParams();
  const roomsRef = useRef(null);
  const [dropdownRooms, setDropdownRooms] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const viewSummary = (_) => {
    setShowDropdown(false);
    navigate(`/house/${houseId}/${homeId}/${orderId}/summary`);
  };

  const handleRoomChange = (room) => {
    navigate(`/house/${houseId}/${homeId}/${orderId}`);
    setRoom(room);
    setShowDropdown(false);
  };

  const getActiveRoomName = () => {
    if (summary) {
      return "Kokkuvõte";
    }

    return room.room_name;
  };

  let roomsClassName = "rooms";

  if (dropdownRooms) {
    roomsClassName += " as-dropdown";
  }

  if (showDropdown) {
    roomsClassName += " show";
  }

  useEffect(() => {
    if (roomsRef.current && roomsRef.current.getBoundingClientRect().width > 800) {
      setDropdownRooms(true);
    }
  }, [roomsRef, home]);

  return (
    <div className="header">
      <div className="left">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Reterra" />
          </Link>
        </div>
        {house && home && (
          <Link to="/" className="back-btn">
            <span>{"<-"}</span>
            {house.title}
          </Link>
        )}
      </div>

      {home && (
        <div className="right">
          {dropdownRooms === true && (
            <button className="current-room" onClick={() => setShowDropdown(!showDropdown)}>
              {getActiveRoomName()}

              {showDropdown ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              )}
            </button>
          )}

          <div className={roomsClassName} ref={roomsRef}>
            {home.rooms.map((x, index) => (
              <button
                className={x.room_name === room.room_name && !summary ? "active" : ""}
                onClick={() => handleRoomChange(x)}
                key={index}
              >
                {x.room_name}
              </button>
            ))}

            <button className={summary ? "active" : ""} onClick={viewSummary}>
              Kokkuvõte
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
